import { render, staticRenderFns } from "./Produto.vue?vue&type=template&id=57a77a32&scoped=true&"
import script from "./Produto.vue?vue&type=script&lang=js&"
export * from "./Produto.vue?vue&type=script&lang=js&"
import style0 from "./Produto.vue?vue&type=style&index=0&id=57a77a32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a77a32",
  null
  
)

export default component.exports